import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { BiChevronDown } from "react-icons/bi";

import React from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";

export interface CustomAccordionProps {
  className?: string;
  title: string;
  markdownSource?: string;
  htmlSource?: string;
}

const AccordionTitle = styled.p`
  text-transform: uppercase;
  font-size: 17px;
`;

const YellowChevronDown = styled(BiChevronDown)`
  color: ${(props) => props.theme.color.yellow};
  font-size: 24px;
`;

const CustomAccordionSummary = styled(AccordionSummary)`
  &&& {
    padding: 0;
    .&-content {
      margin: 5px 0px;
    }
  }
`;

const CustomAccordionInner = (props: CustomAccordionProps) => {
  const { title, markdownSource, htmlSource } = props;
  return (
    <Accordion className={props.className} {...props}>
      <CustomAccordionSummary
        expandIcon={<YellowChevronDown />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <AccordionTitle>{title}</AccordionTitle>
      </CustomAccordionSummary>
      <AccordionDetails>
        <div>
          {!htmlSource ? (
            <ReactMarkdown source={markdownSource} />
          ) : (
            <div dangerouslySetInnerHTML={{ __html: htmlSource }} />
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export const CustomAccordion = styled(CustomAccordionInner)`
  &::before {
    display: none;
  }
  &&& {
    box-shadow: none;
    border-radius: 0;
    border-bottom: 1px ${(props) => props.theme.color.gray} solid;
    background-color: ${(props) => props.theme.color.sectionBg};
  }

  &.whiteBG {
    &&& {
      background-color: white;
    }
  }
`;
